<template>
    <div class="sc-success">
        <div class="blank">
            <div class="top-cell">
                <img
                    alt
                    src="@src/assets/images/jobDetail/publish-result.png"
                />
                <p class="p1">职位创建成功！</p>
                <p class="p2">
                    将职位发布至大厂机会，获得
                    <span class="score">20积分</span>
                </p>
            </div>

            <div class="famous-company-wrap">
                <div class="main">
                    <div class="form-wrap">
                        <div class="top-title">
                            <h1>原职位信息</h1>
                            <p></p>
                        </div>
                        <el-form
                            :model="form"
                            disabled
                            label-width="80px"
                        >
                            <el-form-item label="职位名称">
                                <el-input v-model="form.jobName"></el-input>
                            </el-form-item>
                            <el-form-item label="公司名称">
                                <el-input v-model="form.customerName"></el-input>
                            </el-form-item>
                            <el-form-item label="工作地点">
                                <el-input v-model="form.locations"></el-input>
                            </el-form-item>
                            <el-form-item label="经验要求">
                                <el-input v-model="form.experience"></el-input>
                            </el-form-item>
                            <el-form-item label="学历要求">
                                <el-input v-model="form.degree"></el-input>
                            </el-form-item>
                            <el-form-item label="薪资范围">
                                <el-input v-model="form.salary"></el-input>
                            </el-form-item>
                            <el-form-item label="职位描述">
                                <el-input
                                    :rows="4"
                                    type="textarea"
                                    v-model="form.description"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="form-wrap">
                        <div class="top-title">
                            <h1>发布到大厂机会职位信息</h1>
                            <p>请确保职位信息中不透露任何雇主公司信息</p>
                        </div>
                        <el-form
                            :model="publishForm"
                            :rules="publishRule"
                            label-width="80px"
                            ref="publishForm"
                        >
                            <el-form-item
                                label="职位名称"
                                prop="jobName"
                            >
                                <el-input
                                    maxlength="128"
                                    placeholder="职位名称"
                                    v-model="publishForm.jobName"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="公司名称"
                                prop="customerName"
                            >
                                <el-input
                                    maxlength="128"
                                    placeholder="请不要直接写公司名称"
                                    v-model="publishForm.customerName"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="工作地点">
                                <el-input
                                    disabled
                                    v-model="publishForm.locations"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="经验要求">
                                <el-input
                                    disabled
                                    v-model="publishForm.experience"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="学历要求">
                                <el-input
                                    disabled
                                    v-model="publishForm.degree"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="薪资范围">
                                <div class="salary-wrap">
                                    <el-form-item prop="minSalary">
                                        <el-input
                                            placeholder="最低月薪"
                                            v-model.number="publishForm.minSalary"
                                        >
                                            <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item prop="maxSalary">
                                        <el-input
                                            placeholder="最高月薪"
                                            v-model.number="publishForm.maxSalary"
                                        >
                                            <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="职位描述"
                                prop="description"
                            >
                                <el-input
                                    :rows="4"
                                    maxlength="8000"
                                    placeholder="主要负责......"
                                    type="textarea"
                                    v-model="publishForm.description"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

                <div class="select-member">
                    <p class="select-tip">指定其他人可以跟进该职位的投递简历</p>

                    <div class="employees">
                        <el-popover
                            placement="bottom-end"
                            popper-class="team-member-popover static-team-member-popover"
                            trigger="click"
                            v-model="teamMemberPopoverVisible"
                            width="700"
                        >
                            <!-- :date="dateRange" -->
                            <team-member-select
                                :filterLoginUser="true"
                                :isAllMembers="true"
                                :isFamousCompanyOpportunity="true"
                                :isHistoryData="false"
                                :isShowDimission="false"
                                :selected="userIds"
                                :visible="teamMemberPopoverVisible"
                                @item-select-cb="employeeSelect"
                                ref="teamSelect"
                            ></team-member-select>
                            <div
                                class="employee-select-text"
                                slot="reference"
                            >
                                <span
                                    class="text"
                                    v-if="selectedMembersText"
                                >{{ selectedMembersText }}</span>
                                <span
                                    class="placeholder"
                                    v-else
                                >请选择成员</span>
                            </div>
                        </el-popover>
                    </div>
                </div>

                <span
                    class="dialog-footer"
                    slot="footer"
                >
                    <el-button @click="handleCancel">跳 过</el-button>
                    <el-button
                        :loading="confirmLoading"
                        @click="submitForm('publishForm')"
                        type="primary"
                    >提 交</el-button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>


import publicJobService from '#/js/service/publicJobService.js';
import {
    workExperience as workExperienceJson,
    degree as degreeJson
} from '#/js/config/searchField.json';
import { getCityTextById } from '#/js/util/provinceCity.js';
import TeamMemberSelect from '#/component/common/team-member-select.vue';


export default {
    name: 'JobCreateSingleSuccess',
    components: {
        TeamMemberSelect,
    },
    data () {
        return {
            teamMemberPopoverVisible: false,
            selectedMembersText: '',
            userIds: [],
            startDate: "",
            endDate: "",
            // dateRange: [],

            // dialogVisible: false,
            confirmLoading: false,
            form: {
                jobName: '',
                customerName: '',

                locations: '',
                experience: '',
                degree: '',

                salary: '',
                description: '',
            },
            publishForm: {
                jobName: '',
                customerName: '',

                locations: '',
                experience: '',
                degree: '',

                minSalary: '',
                maxSalary: '',
                description: '',
            },

            publicJobId: '',

            publishRule: {
                jobName: [
                    { required: true, message: '请输入职位名称', trigger: ['blur', 'change'] },
                ],
                customerName: [
                    { required: true, message: '请输入公司名称', trigger: ['blur', 'change'] },
                ],
                // salary: [
                // { required: true, message: '请输入薪资范围', trigger: ['blur', 'change'] },
                //     { validator: this.validateSalary, trigger: ['blur', 'change'] },
                // ],
                minSalary: [
                    { required: true, message: '请填写最低月薪', trigger: ['change'] },
                    { type: 'number', min: 1, max: 999, message: '最低月薪范围是1-999', trigger: 'change' },
                    { type: 'number', validator: this.validateMinSalary, trigger: ['blur'] },
                ],
                maxSalary: [
                    { required: true, message: '请填写最高月薪', trigger: ['change'] },
                    { type: 'number', min: 1, max: 999, message: '最高月薪范围是1-999', trigger: 'change' },
                    { type: 'number', validator: this.validateMaxSalary, trigger: ['blur'] },
                ],
                description: [
                    { required: true, message: '请输入职位描述', trigger: ['blur', 'change'] },
                ],
            },

            singleJobCreateDetail: {},
        };
    },
    created () { },
    mounted () {
        this.init();
    },
    methods: {
        init () {
            this.singleJobCreateDetail = JSON.parse(sessionStorage.getItem('singleJobCreateDetail'));
            console.log(`singleJobCreateDetail:`, this.singleJobCreateDetail);
            this.show({
                ...this.singleJobCreateDetail,
                locationIds: this.singleJobCreateDetail.locations,
                id: this.singleJobCreateDetail.jobId,
            });
        },
        filterDegree (val) {
            return degreeJson[String(val)];
        },
        filteWorkExperience (val) {
            return workExperienceJson[String(val)];
        },
        filteLocations (val) {
            let locationsText = [];
            if (!val) return '';
            val.forEach(el => {
                locationsText.push(getCityTextById(el));
            });
            return locationsText.join(',');
        },

        // 选择成员
        employeeSelect (userIds, text) {
            this.userIds = userIds || [];
            this.selectedMembersText = text;
            this.teamMemberPopoverVisible = false;
        },

        show (originalInfo, publicInfo, publicJobId) {
            // this.dialogVisible = true;

            this.$nextTick(() => {
                console.log(this.$refs);
                // this.$refs.teamSelect.getHistoryTeamData(false);
                this.$refs.teamSelect.getTeamMembers();
            });

            this.publicJobId = publicJobId;

            this.form = {
                jobName: originalInfo.name || originalInfo.jobName,
                customerName: originalInfo.customerName,

                locations: originalInfo.location || this.filteLocations(originalInfo.locationIds),
                experience: originalInfo.yearOfExperience || this.filteWorkExperience(originalInfo.minYearOfExperience),
                degree: originalInfo.degree || this.filterDegree(originalInfo.minDegree),

                salary: originalInfo.salary || `${originalInfo.minSalary}K - ${originalInfo.maxSalary}K`,
                description: originalInfo.description,
            };

            this.publishForm = {
                jobId: originalInfo.id, // 源职位id
                jobName: publicJobId ? publicInfo.jobName : originalInfo.name,
                customerName: publicJobId ? publicInfo.customerName : '某大厂',

                locations: publicJobId ? this.filteLocations(publicInfo.locationIds) : (originalInfo.location || this.filteLocations(originalInfo.locationIds)),
                experience: publicJobId ? this.filteWorkExperience(publicInfo.minYearOfExperience) : (originalInfo.yearOfExperience || this.filteWorkExperience(originalInfo.minYearOfExperience)),
                degree: publicJobId ? this.filterDegree(publicInfo.minDegree) : (originalInfo.degree || this.filterDegree(originalInfo.minDegree)),

                minSalary: publicJobId ? publicInfo.minSalary : originalInfo.minSalary || '',
                maxSalary: publicJobId ? publicInfo.maxSalary : originalInfo.maxSalary || '',
                description: publicJobId ? publicInfo.description : originalInfo.description,
            };

            // 获取选择的成员id和显示文本
            this.userIds = [];
            this.selectedMembersText = '';
            this.userIds = publicInfo?.followUpPeople.map(el => {
                return el.userId;
            });
            if (publicInfo?.followUpPeople?.length > 0) {
                publicInfo?.followUpPeople.map(el => {
                    if (this.selectedMembersText == '') {
                        this.selectedMembersText = el.realName + '@' + el.nickname;
                    } else {
                        this.selectedMembersText = this.selectedMembersText + '，' + el.realName + '@' + el.nickname;
                    }
                });
            } else {
                this.selectedMembersText = '';
            }
        },
        validateMinSalary (rule, value, callback) {
            if (this.publishForm.minSalary && this.publishForm.maxSalary && this.publishForm.minSalary > this.publishForm.maxSalary) {
                callback(new Error(`最小值要小于最大值`));
            } else {
                setTimeout(() => {
                    if (this.publishForm.maxSalary) {
                        this.$refs.publishForm?.validateField('maxSalary');
                    }
                });
                callback();
            }
        },
        validateMaxSalary (rule, value, callback) {
            if (this.publishForm.minSalary && this.publishForm.maxSalary && this.publishForm.minSalary > this.publishForm.maxSalary) {
                callback(new Error(`最小值要小于最大值`));
            } else {
                setTimeout(() => {
                    if (this.publishForm.minSalary) {
                        this.$refs.publishForm.validateField('minSalary');
                    }
                });
                callback();
            }
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const params = {
                        ...this.publishForm,
                        followUpPeople: [],
                    };
                    console.log(this.userIds);
                    if (this.userIds?.length > 0) {
                        params.followUpPeople = this.userIds;
                    }

                    this.confirmLoading = true;

                    if (this.publicJobId) { // 编辑
                        params.id = this.publicJobId;
                        publicJobService.editPublicJob(params)
                            .then(res => {
                                shortTips(`编辑成功，职位将在审核通过后发布`);
                                // this.handleClose();
                            })
                            .finally(() => {
                                this.confirmLoading = false;
                            });
                    } else { // 创建
                        publicJobService.createPublicJob(params)
                            .then(res => {
                                shortTips(`提交成功，职位将在审核通过后发布`);
                                // this.handleClose();
                                setTimeout(() => {
                                    location.href = `/#/corporationOpportunity`;
                                }, 1500);
                            })
                            .finally(() => {
                                this.confirmLoading = false;
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm (formName) {
            this.$refs[formName].resetFields();
        },
        handleClose () {
            this.resetForm('publishForm');
            // this.dialogVisible = false;
            // location.href = `/#${res.jobDetailUrl.split('#')[1]}`;

            location.href = `/#/Job/${this.singleJobCreateDetail.jobId}`;
        },
        handleCancel () {
            location.href = `/#/Job/${this.singleJobCreateDetail.jobId}`;
        },

    },
}
</script>

<style lang="scss" scope>
.sc-success {
    height: 100%;
    overflow-y: auto;
    margin: 20px 20px 30px;
    padding-bottom: 20px;
    .blank {
        background-color: #ffffff;
        border-radius: 8px;
        padding-bottom: 20px;
        padding-top: 52px;
        box-sizing: border-box;
        .top-cell {
            text-align: center;
            > img {
                width: 110px;
                height: 110px;
            }
            .p1 {
                color: #444;
                font-size: 24px;
                margin-top: 31px;
            }
            .p2 {
                color: #444;
                font-size: 16px;
                margin-top: 30px;
                .score {
                    color: #fa765d;
                }
            }
        }

        .famous-company-wrap {
            width: 830px;
            margin: 30px auto 30px;
            box-sizing: border-box;
            border: 1px solid #eee;

            .main {
                border-bottom: 1px solid #eee;
                display: flex;
                .form-wrap {
                    flex: 1;
                    box-sizing: border-box;
                    padding: 25px 20px;
                    &:first-child {
                        border-right: 1px solid #eee;
                    }

                    .top-title {
                        height: 40px;
                        margin-bottom: 16px;
                        > h1 {
                            &::before {
                                display: inline-block;
                                background: $primary;
                                content: '';
                                width: 4px;
                                height: 20px;
                                margin-right: 13px;
                                vertical-align: middle;
                            }
                            color: #333333;
                            font-size: 16px;
                            margin: 0;
                        }
                        > p {
                            color: #e24e3a;
                            font-size: 14px;
                            padding-left: 18px;
                        }
                    }

                    .salary-wrap {
                        display: flex;
                        justify-content: space-between;
                        > div {
                            flex: 1;
                            max-width: 48%;
                        }
                    }
                }
            }

            .select-member {
                .select-tip {
                    margin: 20px;
                    text-align: center;
                    color: #666666;
                    font-size: 14px;
                }

                .employees {
                    position: relative;
                    width: 480px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                    margin: 0 auto;
                    border: 1px solid #d3d3d3;
                    border-radius: 4px;

                    .employee-select-text {
                        display: flex;
                        align-items: center;

                        .text {
                            flex: 1;
                            flex-grow: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .placeholder {
                            font-size: 14px;
                            color: #a3afb7;
                        }
                    }

                    .employee-select-arrow {
                        margin-right: 0;
                    }
                }
            }

            .dialog-footer {
                display: block;
                text-align: center;
                padding-top: 39px;
                margin-bottom: 20px;
            }
        }
    }
}
</style>