var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sc-success" }, [
    _c("div", { staticClass: "blank" }, [
      _vm._m(0),
      _c("div", { staticClass: "famous-company-wrap" }, [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _vm._m(1),
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    disabled: "",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.jobName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jobName", $$v)
                          },
                          expression: "form.jobName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.customerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customerName", $$v)
                          },
                          expression: "form.customerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作地点" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.locations,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "locations", $$v)
                          },
                          expression: "form.locations",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经验要求" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.experience,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "experience", $$v)
                          },
                          expression: "form.experience",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "学历要求" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.degree,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "degree", $$v)
                          },
                          expression: "form.degree",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "薪资范围" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.salary,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "salary", $$v)
                          },
                          expression: "form.salary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位描述" } },
                    [
                      _c("el-input", {
                        attrs: { rows: 4, type: "textarea" },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _vm._m(2),
              _c(
                "el-form",
                {
                  ref: "publishForm",
                  attrs: {
                    model: _vm.publishForm,
                    rules: _vm.publishRule,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位名称", prop: "jobName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "128", placeholder: "职位名称" },
                        model: {
                          value: _vm.publishForm.jobName,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "jobName", $$v)
                          },
                          expression: "publishForm.jobName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "customerName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "128",
                          placeholder: "请不要直接写公司名称",
                        },
                        model: {
                          value: _vm.publishForm.customerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "customerName", $$v)
                          },
                          expression: "publishForm.customerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作地点" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.publishForm.locations,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "locations", $$v)
                          },
                          expression: "publishForm.locations",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经验要求" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.publishForm.experience,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "experience", $$v)
                          },
                          expression: "publishForm.experience",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "学历要求" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.publishForm.degree,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "degree", $$v)
                          },
                          expression: "publishForm.degree",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "薪资范围" } }, [
                    _c(
                      "div",
                      { staticClass: "salary-wrap" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "minSalary" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "最低月薪" },
                                model: {
                                  value: _vm.publishForm.minSalary,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.publishForm,
                                      "minSalary",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "publishForm.minSalary",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("K(千)  "),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "maxSalary" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "最高月薪" },
                                model: {
                                  value: _vm.publishForm.maxSalary,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.publishForm,
                                      "maxSalary",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "publishForm.maxSalary",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("K(千)  "),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位描述", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 4,
                          maxlength: "8000",
                          placeholder: "主要负责......",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.publishForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.publishForm, "description", $$v)
                          },
                          expression: "publishForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "select-member" }, [
          _c("p", { staticClass: "select-tip" }, [
            _vm._v("指定其他人可以跟进该职位的投递简历"),
          ]),
          _c(
            "div",
            { staticClass: "employees" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-end",
                    "popper-class":
                      "team-member-popover static-team-member-popover",
                    trigger: "click",
                    width: "700",
                  },
                  model: {
                    value: _vm.teamMemberPopoverVisible,
                    callback: function ($$v) {
                      _vm.teamMemberPopoverVisible = $$v
                    },
                    expression: "teamMemberPopoverVisible",
                  },
                },
                [
                  _c("team-member-select", {
                    ref: "teamSelect",
                    attrs: {
                      filterLoginUser: true,
                      isAllMembers: true,
                      isFamousCompanyOpportunity: true,
                      isHistoryData: false,
                      isShowDimission: false,
                      selected: _vm.userIds,
                      visible: _vm.teamMemberPopoverVisible,
                    },
                    on: { "item-select-cb": _vm.employeeSelect },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "employee-select-text",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _vm.selectedMembersText
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.selectedMembersText)),
                          ])
                        : _c("span", { staticClass: "placeholder" }, [
                            _vm._v("请选择成员"),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c("el-button", { on: { click: _vm.handleCancel } }, [
              _vm._v("跳 过"),
            ]),
            _c(
              "el-button",
              {
                attrs: { loading: _vm.confirmLoading, type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm("publishForm")
                  },
                },
              },
              [_vm._v("提 交")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-cell" }, [
      _c("img", {
        attrs: {
          alt: "",
          src: require("@src/assets/images/jobDetail/publish-result.png"),
        },
      }),
      _c("p", { staticClass: "p1" }, [_vm._v("职位创建成功！")]),
      _c("p", { staticClass: "p2" }, [
        _vm._v(
          "\n                将职位发布至大厂机会，获得\n                "
        ),
        _c("span", { staticClass: "score" }, [_vm._v("20积分")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-title" }, [
      _c("h1", [_vm._v("原职位信息")]),
      _c("p"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-title" }, [
      _c("h1", [_vm._v("发布到大厂机会职位信息")]),
      _c("p", [_vm._v("请确保职位信息中不透露任何雇主公司信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }